import { render, staticRenderFns } from "./SportsOriginalSpecial.vue?vue&type=template&id=e86e7f20&scoped=true&"
import script from "./SportsOriginalSpecial.vue?vue&type=script&lang=js&"
export * from "./SportsOriginalSpecial.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e86e7f20",
  null
  
)

export default component.exports